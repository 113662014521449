.prospect-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.1rem;
  line-height: 14px;
  letter-spacing: 0.2px;
}

.prospect-table .table-head {
  text-align: left;
}

.prospect-table thead,
.prospect-table tbody {
  filter: drop-shadow(0px 4px 4px rgba(37, 40, 43, 0.12));
}

.prospect-table .table-head-item-wrapper {
  background: #f9f9fa;
}

.prospect-table .table-head-item-wrapper:hover {
  background: #e5f2ff;
}

.prospect-table .table-column-item-wrapper {
  background: #ffffff;
}

.prospect-table .table-column-item-wrapper:hover {
  background: #e5f2ff;
}

.prospect-table .table-head-item,
.prospect-table .table-column-item {
  padding: 1.2rem 1rem;
}

.prospect-table .column-active {
  background: #e5f2ff;
}
