.sidebar {
  background: #ffffff;
  height: 100vh;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.sidebar .sidebar-logo {
  min-height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #e8e8e8;
  margin-bottom: 2rem;
}

.sidebar .logo {
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 2.6rem;
  letter-spacing: 0.2px;
  color: var(--primary-color);
  text-decoration: none;
}

.sidebar .logo-icon {
  padding-right: 1rem;
}

.sidebar .sidebar-links {
  list-style: none;
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 2.5rem;
}

.sidebar .sidebar-links-second {
  list-style: none;
  padding: 2rem 0;
}

.sidebar .sidebar-links-item {
  padding: 1rem 0;
  padding-left: 2rem;
  border-left: 2px solid #fff;
}

.sidebar .sidebar-links-item:hover {
  background: #f5faff;
  border-left: 2px solid var(--primary-color);
}

.sidebar .link-active {
  background: #f5faff;
  color: var(--primary-color);
  border-left: 2px solid var(--primary-color);
}

.sidebar .sidebar-links-item-link-active {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .sidebar-links-item-link:hover {
  color: var(--primary-color);
}

.sidebar .sidebar-links-item-link {
  color: #25282b;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .sidebar-links-item-icon {
  padding-right: 1.5rem;
  fill: currentColor;
  color: currentColor;
}

.sidebar-links-item-name {
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
}

.sub-menu-from-properties {
  list-style: none;
}

.sub-menu-from-properties-items {
  padding: 0.5rem 0;
  padding-left: 7rem;
}

.sub-menu-from-properties-items-link {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
  color: #0d1621;
  text-decoration: none;
}
