.navbar {
  min-height: 6rem;
  width: 100%;
  border-bottom: 2px solid #e8e8e8;
  margin-bottom: 2rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .navbar-left {
  padding-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .navbar-right {
  padding-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .navbar-search-icon {
  padding-right: 1rem;
}

.navbar .navbar-search {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #a0a4a8;
  border: none;
  width: 200px;
}

.navbar .navbar-search:focus {
  border: none;
  outline: none;
}

.navbar .navbar-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
}

.navbar .profile-picture {
  background: #cce5ff;
  color: #007eff;
  padding: 1.5rem;
  border-radius: 50%;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
}

.navbar .profile-details {
  padding: 0 2rem;
}

.navbar .profile-name {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: #52575c;
}

.navbar .profile-designation {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: #a0a4a8;
}
