.tenant-summary-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.tenant-summary-top .left-link,
.tenant-summary-top .right-link {
  display: flex;
  align-items: center;
}

.tenant-summary-top .summary-link {
  color: var(--grey-color);
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0 1rem;
}

.tenant-summary-main .tenant-summary-left .profile {
  display: flex;
  align-items: flex-start;
  padding-bottom: 2rem;
}

.tenant-summary-main .tenant-summary-left .profile .profile-pic {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: var(--primary-color);
  background: #cce5ff;
  padding: 1rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.tenant-summary-main .tenant-summary-left .profile .profile-details .name {
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.1px;
  color: var(--primary-color);
  font-weight: 500;
}

.tenant-summary-main .tenant-summary-left .profile .profile-details .type {
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
  color: #25282b;
  font-weight: 500;
}

.tenant-summary-main .tenant-summary-left .profile .profile-details .address {
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: #a0a4a8;
  font-weight: 500;
}

.tenant-summary-main .column-filter-menu {
  height: 60rem;
}

.tenant-summary-main {
  display: flex;
  align-items: baseline;
}

.tenant-summary-main .tenant-summary-left {
  width: 25.5rem;
}

.tenant-summary-main .tenant-summary-right {
  width: calc(100% - 25.5rem);
  margin-left: 3rem;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 4rem;
}

.tenant-summary-main .tenant-summary-right .left-half {
  padding-left: 1rem;
  width: 40%;
}

.tenant-summary-main .left-half .mini-top-section {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  font-weight: 500;
}

.mini-section-title {
  font-size: 1.3rem;
}

.mini-section-edit {
  font-size: 0.9rem;
}

.mini-section-edit img {
  margin-right: 0.5rem;
}

.mini-middle-section,
.mini-lower-section {
  display: flex;
  /* justify-content: space-between; */
  font-size: 1.1rem;
  padding-bottom: 2rem;
}

.mini-middle-section .mini-section-left,
.mini-lower-section .mini-section-left {
  width: 50%;
}

.mini-middle-section .item-title,
.mini-lower-section .item-title {
  padding-bottom: 0.5rem;
  letter-spacing: 0.2px;
  color: #52575c;
}

.mini-middle-section .item-value,
.mini-lower-section .item-value {
  color: #25282b;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0.1px;
}

.note-title {
  font-size: 1.3rem;
  line-height: 16px;
  letter-spacing: 0.01em;
}
