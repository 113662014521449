.btn {
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;
  letter-spacing: 0.2px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.btn-big {
  padding: 1.5rem 2.5rem;
}

.btn-small {
  padding: 1rem 2.5rem;
}

.btn:active,
.btn:focus {
  outline: none;
}

.btn-icon {
  padding-right: 0.5rem;
  color: currentColor;
  fill: currentColor;
}

.btn-primary {
  color: #fff;
  background: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16);
}

.btn-ghost {
  color: #fff;
  background: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
