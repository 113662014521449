.properties-heading-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 3rem;
}

.properties-heading {
  color: var(--primary-color);
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.1px;
}

.properties-filter-section {
  margin-bottom: 2rem;
}

.properties-cards-section {
  display: flex;
  flex-wrap: wrap;
}

.properties-cards-section div:last-child {
  align-self: start;
}

.properties-single-card {
  width: 32%;
  padding: 1rem;
}

@media only screen and (max-width: 1235px) {
  .properties-single-card {
    width: 49%;
    padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 950px) {
  .properties-single-card {
    width: 100%;
    padding-bottom: 2rem;
  }
}
