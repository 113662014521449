.filter-list {
  list-style: none;
}

.filter-item {
  display: inline-block;
  color: #52575c;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 1rem 2rem;
  box-sizing: content-box;
  border-bottom: 2px solid#E8E8E8;
  cursor: pointer;
}

.filter-active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}
