.properties-card {
	background: #ffffff;
	border: 1px solid #e8e8e8;
	box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	width: 100%;
	padding: 2rem 2.5rem;
}

.properties-card-header {
	text-align: center;
	margin-bottom: 2rem;
}

.properties-card-name {
	font-weight: 600;
	font-size: 1.3rem;
	line-height: 2rem;

	text-align: center;
	letter-spacing: 0.1px;
	color: #0d1621;
}

.properties-card-address {
	font-weight: 500;
	font-size: 1.1rem;
	line-height: 1.6rem;
	letter-spacing: 0.2px;
	color: #a0a4a8;
}

.properties-card-body {
	background: #f9f9fa;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 6px;
	padding: 1rem 0;
}

.properties-card-detail-section {
	display: flex;
	list-style: none;
	justify-content: space-evenly;
}

.properties-card-detail-item {
	text-align: center;
	width: 33.33%;
}

.properties-card-detail-item > div {
	display: block;
}

.properties-card-detail-item-number {
	font-weight: 600;
	font-size: 1.3rem;
	line-height: 1.6rem;
	text-align: center;
	letter-spacing: 0.2px;
	color: #52575c;
}

.properties-card-detail-item-details {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4rem;
	text-align: center;
	letter-spacing: 0.2px;
	color: #a0a4a8;
}

.properties-card-occupied {
	text-align: center;
	padding: 1.5rem 0;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4rem;
	letter-spacing: 0.2px;
	color: #a0a4a8;
}

.properties-card-footer {
	width: 100%;
	display: flex;
	text-align: center;
}

.properties-card-footer > div {
	width: 50%;
}

.properties-card-edit-button,
.properties-card-view-more-button {
	cursor: pointer;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;
	color: #52575c;
	display: flex;
	justify-content: center;
}

.properties-card-edit-button {
	border-right: 2px solid #e8e8e8;
}

.properties-card-button-icon {
	padding-left: 1rem;
}
