.tenants-top-part {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 3rem;
}

.tenants-top-part .top-buttons {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.tenants-bar {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(37, 40, 43, 0.12);
  display: flex;
  padding: 1.5rem 6rem;
  align-items: center;
  justify-content: space-between;
}

.tenants-bar-left {
  display: flex;
  align-content: center;
  align-items: center;
}

.tenants-bar-right {
  font-size: 1.1rem;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #a0a4a8;
}
