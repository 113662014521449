.tenants-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.1rem;
  line-height: 14px;
  letter-spacing: 0.2px;
}

.tenants-table .table-head {
  text-align: left;
}

.tenants-table thead,
.tenants-table tbody {
  filter: drop-shadow(0px 4px 4px rgba(37, 40, 43, 0.12));
}

.tenants-table .table-head-item-wrapper {
  background: #f9f9fa;
}

.tenants-table .table-head-item-wrapper:hover {
  background: #e5f2ff;
}

.tenants-table .table-column-item-wrapper {
  background: #ffffff;
}

.tenants-table .table-column-item-wrapper:hover {
  background: #e5f2ff;
}

.tenants-table .table-head-item,
.tenants-table .table-column-item {
  padding: 1.2rem 1rem;
}

.tenants-table .column-active {
  background: #e5f2ff;
}

.tenants-table .lease-status-Active {
  background-color: #bcf3d2;
  color: #1ab759;
  border-radius: 10px;
  text-align: center;
  padding: 0.5rem;
}

.tenants-table .lease-status-Expiring {
  background-color: #ffe8b1;
  color: #e89806;
  border-radius: 10px;
  text-align: center;
  padding: 0.5rem;
}

.tenants-table .lease-status-Expired {
  background-color: #ffc8c8;
  color: #e93c3c;
  border-radius: 10px;
  text-align: center;
  padding: 0.5rem;
}
