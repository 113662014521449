.react-root {
	/* display: flex; */
}

.left-side {
	width: 25rem;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
}

.right-side {
	margin-left: 25rem; /* Same as the width of the sidenav */
}

.right-side .navbar {
	position: fixed;
	top: 0;
	width: calc(100% - 25rem);
}

.right-side .main-content {
	margin-top: 6rem;
	padding: 4rem;
}
