.filter-menu {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  font-weight: 500;
}

.filter-menu .filter-menu-item {
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e8e8e8;
  border-right: 2px solid #e8e8e8;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filter-menu .filter-menu-item .filter-menu-icon {
  margin-right: 1rem;
}

.filter-menu .filter-menu-title {
  font-size: 1.3rem;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #25282b;
}

.filter-menu .filter-menu-subtitle {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #a0a4a8;
}

.filter-menu .active {
  border-right: 2px solid #007eff;
}

.filter-menu .active .filter-menu-title {
  color: #007eff;
}
