@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap);
.sidebar {
  background: #ffffff;
  height: 100vh;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.sidebar .sidebar-logo {
  min-height: 6rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #e8e8e8;
  margin-bottom: 2rem;
}

.sidebar .logo {
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 2.6rem;
  letter-spacing: 0.2px;
  color: var(--primary-color);
  text-decoration: none;
}

.sidebar .logo-icon {
  padding-right: 1rem;
}

.sidebar .sidebar-links {
  list-style: none;
  border-bottom: 2px solid #e8e8e8;
  padding-bottom: 2.5rem;
}

.sidebar .sidebar-links-second {
  list-style: none;
  padding: 2rem 0;
}

.sidebar .sidebar-links-item {
  padding: 1rem 0;
  padding-left: 2rem;
  border-left: 2px solid #fff;
}

.sidebar .sidebar-links-item:hover {
  background: #f5faff;
  border-left: 2px solid var(--primary-color);
}

.sidebar .link-active {
  background: #f5faff;
  color: var(--primary-color);
  border-left: 2px solid var(--primary-color);
}

.sidebar .sidebar-links-item-link-active {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .sidebar-links-item-link:hover {
  color: var(--primary-color);
}

.sidebar .sidebar-links-item-link {
  color: #25282b;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar .sidebar-links-item-icon {
  padding-right: 1.5rem;
  fill: currentColor;
  color: currentColor;
}

.sidebar-links-item-name {
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
}

.sub-menu-from-properties {
  list-style: none;
}

.sub-menu-from-properties-items {
  padding: 0.5rem 0;
  padding-left: 7rem;
}

.sub-menu-from-properties-items-link {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
  color: #0d1621;
  text-decoration: none;
}

.navbar {
  min-height: 6rem;
  width: 100%;
  border-bottom: 2px solid #e8e8e8;
  margin-bottom: 2rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .navbar-left {
  padding-left: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .navbar-right {
  padding-right: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar .navbar-search-icon {
  padding-right: 1rem;
}

.navbar .navbar-search {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #a0a4a8;
  border: none;
  width: 200px;
}

.navbar .navbar-search:focus {
  border: none;
  outline: none;
}

.navbar .navbar-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
}

.navbar .profile-picture {
  background: #cce5ff;
  color: #007eff;
  padding: 1.5rem;
  border-radius: 50%;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
}

.navbar .profile-details {
  padding: 0 2rem;
}

.navbar .profile-name {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: #52575c;
}

.navbar .profile-designation {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: #a0a4a8;
}

.react-root {
	/* display: flex; */
}

.left-side {
	width: 25rem;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
}

.right-side {
	margin-left: 25rem; /* Same as the width of the sidenav */
}

.right-side .navbar {
	position: fixed;
	top: 0;
	width: calc(100% - 25rem);
}

.right-side .main-content {
	margin-top: 6rem;
	padding: 4rem;
}

.btn {
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;
  letter-spacing: 0.2px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.btn-big {
  padding: 1.5rem 2.5rem;
}

.btn-small {
  padding: 1rem 2.5rem;
}

.btn:active,
.btn:focus {
  outline: none;
}

.btn-icon {
  padding-right: 0.5rem;
  color: currentColor;
  fill: currentColor;
}

.btn-primary {
  color: #fff;
  background: var(--primary-color);
  box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16);
}

.btn-ghost {
  color: #fff;
  background: transparent;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.properties-card {
	background: #ffffff;
	border: 1px solid #e8e8e8;
	box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	width: 100%;
	padding: 2rem 2.5rem;
}

.properties-card-header {
	text-align: center;
	margin-bottom: 2rem;
}

.properties-card-name {
	font-weight: 600;
	font-size: 1.3rem;
	line-height: 2rem;

	text-align: center;
	letter-spacing: 0.1px;
	color: #0d1621;
}

.properties-card-address {
	font-weight: 500;
	font-size: 1.1rem;
	line-height: 1.6rem;
	letter-spacing: 0.2px;
	color: #a0a4a8;
}

.properties-card-body {
	background: #f9f9fa;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	border-radius: 6px;
	padding: 1rem 0;
}

.properties-card-detail-section {
	display: flex;
	list-style: none;
	justify-content: space-evenly;
}

.properties-card-detail-item {
	text-align: center;
	width: 33.33%;
}

.properties-card-detail-item > div {
	display: block;
}

.properties-card-detail-item-number {
	font-weight: 600;
	font-size: 1.3rem;
	line-height: 1.6rem;
	text-align: center;
	letter-spacing: 0.2px;
	color: #52575c;
}

.properties-card-detail-item-details {
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4rem;
	text-align: center;
	letter-spacing: 0.2px;
	color: #a0a4a8;
}

.properties-card-occupied {
	text-align: center;
	padding: 1.5rem 0;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4rem;
	letter-spacing: 0.2px;
	color: #a0a4a8;
}

.properties-card-footer {
	width: 100%;
	display: flex;
	text-align: center;
}

.properties-card-footer > div {
	width: 50%;
}

.properties-card-edit-button,
.properties-card-view-more-button {
	cursor: pointer;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.2px;
	color: #52575c;
	display: flex;
	justify-content: center;
}

.properties-card-edit-button {
	border-right: 2px solid #e8e8e8;
}

.properties-card-button-icon {
	padding-left: 1rem;
}

.properties-heading-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 3rem;
}

.properties-heading {
  color: var(--primary-color);
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.1px;
}

.properties-filter-section {
  margin-bottom: 2rem;
}

.properties-cards-section {
  display: flex;
  flex-wrap: wrap;
}

.properties-cards-section div:last-child {
  align-self: start;
}

.properties-single-card {
  width: 32%;
  padding: 1rem;
}

@media only screen and (max-width: 1235px) {
  .properties-single-card {
    width: 49%;
    padding-bottom: 2rem;
  }
}

@media only screen and (max-width: 950px) {
  .properties-single-card {
    width: 100%;
    padding-bottom: 2rem;
  }
}

.filter-list {
  list-style: none;
}

.filter-item {
  display: inline-block;
  color: #52575c;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.2px;
  padding: 1rem 2rem;
  box-sizing: content-box;
  border-bottom: 2px solid#E8E8E8;
  cursor: pointer;
}

.filter-active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.btn-dropdown {
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: 0.2px;
  color: #52575c;
  padding: 1.5rem 1rem;
  display: flex;
  cursor: pointer;
}

.btn-dropdown-icon {
  margin-left: 2rem;
  color: currentColor;
}

.tenants-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.1rem;
  line-height: 14px;
  letter-spacing: 0.2px;
}

.tenants-table .table-head {
  text-align: left;
}

.tenants-table thead,
.tenants-table tbody {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(37, 40, 43, 0.12));
          filter: drop-shadow(0px 4px 4px rgba(37, 40, 43, 0.12));
}

.tenants-table .table-head-item-wrapper {
  background: #f9f9fa;
}

.tenants-table .table-head-item-wrapper:hover {
  background: #e5f2ff;
}

.tenants-table .table-column-item-wrapper {
  background: #ffffff;
}

.tenants-table .table-column-item-wrapper:hover {
  background: #e5f2ff;
}

.tenants-table .table-head-item,
.tenants-table .table-column-item {
  padding: 1.2rem 1rem;
}

.tenants-table .column-active {
  background: #e5f2ff;
}

.tenants-table .lease-status-Active {
  background-color: #bcf3d2;
  color: #1ab759;
  border-radius: 10px;
  text-align: center;
  padding: 0.5rem;
}

.tenants-table .lease-status-Expiring {
  background-color: #ffe8b1;
  color: #e89806;
  border-radius: 10px;
  text-align: center;
  padding: 0.5rem;
}

.tenants-table .lease-status-Expired {
  background-color: #ffc8c8;
  color: #e93c3c;
  border-radius: 10px;
  text-align: center;
  padding: 0.5rem;
}

.tenants-top-part {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 3rem;
}

.tenants-top-part .top-buttons {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.tenants-bar {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(37, 40, 43, 0.12);
  display: flex;
  padding: 1.5rem 6rem;
  align-items: center;
  justify-content: space-between;
}

.tenants-bar-left {
  display: flex;
  align-content: center;
  align-items: center;
}

.tenants-bar-right {
  font-size: 1.1rem;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #a0a4a8;
}

.prospect-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.1rem;
  line-height: 14px;
  letter-spacing: 0.2px;
}

.prospect-table .table-head {
  text-align: left;
}

.prospect-table thead,
.prospect-table tbody {
  -webkit-filter: drop-shadow(0px 4px 4px rgba(37, 40, 43, 0.12));
          filter: drop-shadow(0px 4px 4px rgba(37, 40, 43, 0.12));
}

.prospect-table .table-head-item-wrapper {
  background: #f9f9fa;
}

.prospect-table .table-head-item-wrapper:hover {
  background: #e5f2ff;
}

.prospect-table .table-column-item-wrapper {
  background: #ffffff;
}

.prospect-table .table-column-item-wrapper:hover {
  background: #e5f2ff;
}

.prospect-table .table-head-item,
.prospect-table .table-column-item {
  padding: 1.2rem 1rem;
}

.prospect-table .column-active {
  background: #e5f2ff;
}

.filter-menu {
  width: 100%;
  min-height: 100%;
  background: #fff;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  font-weight: 500;
}

.filter-menu .filter-menu-item {
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e8e8e8;
  border-right: 2px solid #e8e8e8;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filter-menu .filter-menu-item .filter-menu-icon {
  margin-right: 1rem;
}

.filter-menu .filter-menu-title {
  font-size: 1.3rem;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #25282b;
}

.filter-menu .filter-menu-subtitle {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #a0a4a8;
}

.filter-menu .active {
  border-right: 2px solid #007eff;
}

.filter-menu .active .filter-menu-title {
  color: #007eff;
}

.tenant-summary-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;
}

.tenant-summary-top .left-link,
.tenant-summary-top .right-link {
  display: flex;
  align-items: center;
}

.tenant-summary-top .summary-link {
  color: var(--grey-color);
  text-decoration: none;
  font-size: 1.2rem;
  padding: 0 1rem;
}

.tenant-summary-main .tenant-summary-left .profile {
  display: flex;
  align-items: flex-start;
  padding-bottom: 2rem;
}

.tenant-summary-main .tenant-summary-left .profile .profile-pic {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: var(--primary-color);
  background: #cce5ff;
  padding: 1rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.tenant-summary-main .tenant-summary-left .profile .profile-details .name {
  font-size: 2rem;
  line-height: 3rem;
  letter-spacing: 0.1px;
  color: var(--primary-color);
  font-weight: 500;
}

.tenant-summary-main .tenant-summary-left .profile .profile-details .type {
  font-size: 1.3rem;
  line-height: 2rem;
  letter-spacing: 0.1px;
  color: #25282b;
  font-weight: 500;
}

.tenant-summary-main .tenant-summary-left .profile .profile-details .address {
  font-size: 1.3rem;
  line-height: 1.6rem;
  letter-spacing: 0.2px;
  color: #a0a4a8;
  font-weight: 500;
}

.tenant-summary-main .column-filter-menu {
  height: 60rem;
}

.tenant-summary-main {
  display: flex;
  align-items: baseline;
}

.tenant-summary-main .tenant-summary-left {
  width: 25.5rem;
}

.tenant-summary-main .tenant-summary-right {
  width: calc(100% - 25.5rem);
  margin-left: 3rem;
  background: #fff;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 4rem;
}

.tenant-summary-main .tenant-summary-right .left-half {
  padding-left: 1rem;
  width: 40%;
}

.tenant-summary-main .left-half .mini-top-section {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1rem;
  font-weight: 500;
}

.mini-section-title {
  font-size: 1.3rem;
}

.mini-section-edit {
  font-size: 0.9rem;
}

.mini-section-edit img {
  margin-right: 0.5rem;
}

.mini-middle-section,
.mini-lower-section {
  display: flex;
  /* justify-content: space-between; */
  font-size: 1.1rem;
  padding-bottom: 2rem;
}

.mini-middle-section .mini-section-left,
.mini-lower-section .mini-section-left {
  width: 50%;
}

.mini-middle-section .item-title,
.mini-lower-section .item-title {
  padding-bottom: 0.5rem;
  letter-spacing: 0.2px;
  color: #52575c;
}

.mini-middle-section .item-value,
.mini-lower-section .item-value {
  color: #25282b;
  font-weight: 600;
  line-height: 2rem;
  letter-spacing: 0.1px;
}

.note-title {
  font-size: 1.3rem;
  line-height: 16px;
  letter-spacing: 0.01em;
}


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #007eff;
  --grey-color: #a0a4a8;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  background: #f9f9fa;
}

.color-primary {
  color: #007eff !important;
  color: var(--primary-color) !important;
}

.text-underline {
  text-decoration: underline !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

/*Utility class*/
.margin-right-1 {
  margin-right: 1rem !important;
}

.margin-right-2 {
  margin-right: 2rem !important;
}

