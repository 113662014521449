.btn-dropdown {
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: 0.2px;
  color: #52575c;
  padding: 1.5rem 1rem;
  display: flex;
  cursor: pointer;
}

.btn-dropdown-icon {
  margin-left: 2rem;
  color: currentColor;
}
