@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #007eff;
  --grey-color: #a0a4a8;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  background: #f9f9fa;
}

.color-primary {
  color: var(--primary-color) !important;
}

.text-underline {
  text-decoration: underline !important;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
::-moz-placeholder {
  /* Firefox 19+ */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
:-ms-input-placeholder {
  /* IE 10+ */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
:-moz-placeholder {
  /* Firefox 18- */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

/*Utility class*/
.margin-right-1 {
  margin-right: 1rem !important;
}

.margin-right-2 {
  margin-right: 2rem !important;
}
